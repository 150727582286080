/*
* Polls for an element. When an element is found, run a callback.
* Will never callback for an element twice.
*/

export default (selector, callback) => {
  // Elements we've found
  const foundElements = [];

  const findNewElements = () => {
    const elements = window.document.querySelectorAll(selector);

    elements.forEach((element) => {
      // See if we've already found this element
      const alreadyFound = foundElements.some((foundElement) => (
        foundElement === element
      ));

      // If not, then let's call back. Also add the element to
      // the found elements array to prevent calling back again
      if (!alreadyFound) {
        foundElements.push(element);
        callback(element);
      }
    });
  };

  // Run immediately
  findNewElements();

  // Also run every 250 ms
  setInterval(findNewElements, 250);
};
