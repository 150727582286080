import isDebug from './isDebug';

const LOG_PREFIX = '[Beacon debugger]: ';

// eslint-disable-next-line func-names
const debugLog = function () {
  if (isDebug) {
    // 1. Convert args to a normal array
    // eslint-disable-next-line prefer-rest-params
    const args = Array.prototype.slice.call(arguments);

    // 2. Prepend log prefix log string
    args.unshift(LOG_PREFIX);

    // 3. Pass along arguments to console.log
    // eslint-disable-next-line
    console.log.apply(console, args);
  }
};

export default debugLog;
