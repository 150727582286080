import waitForElements from 'utils/waitForElements';
import initForm from './form';
import tracker from './tracker';
import ga from './ga';
import debugLog from './utils/debugLog';
import isDebug from './utils/isDebug';

try {

  // Initialize the web tracker
  const { visitorId } = tracker();

  // Wait for forms. When found, initialize them.
  waitForElements('.beacon-form', (el) => {
    initForm(el, {
      visitorId,
    });
  });

  // Add cross domain tracking to links that point to Beacon forms
  ga();

} catch (e) {

  // Log the error in debug mode
  debugLog(e);

  if (isDebug) {
    // eslint-disable-next-line no-debugger
    debugger;
  }

}
