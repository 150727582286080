import guid from 'mout/random/guid';
import getCookie from 'utils/getCookie';
import setCookie from 'utils/setCookie';

export default () => {

  // literally all this tracker does right now is set a cookie.
  // we could do more going forwards, but for now this is all
  const visitorId = getCookie('beacon_vid') || guid();
  setCookie('beacon_vid', visitorId, 365);

  // return the visitor id, in case someone else needs to use it
  return {
    visitorId,
  };

};
