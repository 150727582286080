module.exports = (name, value, days) => {
  let expires;
  if (days) {
    const date = new Date();
    const timeFromNow = (days * 24 * 60 * 60 * 1000) + (date.getTimezoneOffset() * -60 * 1000);
    date.setTime(date.getTime() + timeFromNow);
    expires = `; expires=${date.toGMTString()}`;
  } else {
    expires = '';
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
};
