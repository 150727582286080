import getParam from 'mout/queryString/getParam';
import setParam from 'mout/queryString/setParam';

export default (el, clientId) => {

  const { href } = el;

  const existingId = getParam(href, 'gaid', false);

  if (existingId !== clientId) {
    const newHref = setParam(href, 'gaid', clientId);
    el.setAttribute('href', newHref);
  }

};
