// Get the cookie id

import isFunction from 'utils/isFunction';

export default () => {

  if (window.ga && isFunction(window.ga.getAll)) {
    let clientId;
    (window.ga.getAll() || []).forEach((tracker) => {
      const id = tracker.get('clientId');
      if (id && !clientId) {
        clientId = id;
      }
    });
    return clientId;
  }

};
