/*
* Check to see if a message sent from the child iframe is a valid message
* and passes the relevant security checks.
*/

import portalHost from 'config/portalHost';
import env from 'config/env';

export default (e, accountSlug, embedId) => {

  // If embed id that's passed up from the child doesn't match the `embedId`
  // then it's not valid.
  const messageId = (e.data || {}).id;
  if (messageId && messageId !== embedId) {
    return false;
  }

  // If prod or staging and invalid origin, then invalid message
  if (
    (['production', 'staging'].indexOf(env) !== -1)
    && (e.origin !== `https://${accountSlug}.${portalHost}`)
  ) {
    return false;
  }

  // If dev and not valid origin, then false
  if (env === 'development' && e.origin !== `http://${portalHost}`) {
    return false;
  }

  return true;

};
