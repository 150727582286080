import waitForElements from 'utils/waitForElements';
import waitForClientId from './waitForClientId';
import linkAnchorCrossDomain from './linkAnchorCrossDomain';

export default () => {

  // Wait for the client ID. It may never turn up, but keep waiting for a while
  waitForClientId((clientId) => {

    // Look out for all link elements, then evaluate them to see if they're
    // beacon forms elements. If so, set up cross domain linking
    waitForElements('a', (el) => {
      if ((el.href || '').indexOf('.beaconforms.com') !== -1) {
        linkAnchorCrossDomain(el, clientId);
      }
    });

  });

};
