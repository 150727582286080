import guid from 'mout/random/guid';
import encode from 'mout/queryString/encode';
import parse from 'mout/queryString/parse';
import snakeCase from 'mout/string/underscore';
import env from 'config/env';
import portalHost from 'config/portalHost';
import getOffset from 'utils/getOffset';
import getFrameWindow from 'utils/getFrameWindow';
import debugLog from 'utils/debugLog';
import confetti from 'utils/confetti';
import isFunction from 'utils/isFunction';
import isNumber from 'utils/isNumber';
import waitForClientId from '../ga/waitForClientId';
import isValidMessage from './isValidMessage';

const isValid = (el) => {
  const {
    account,
    form,
  } = el.dataset;
  return !!(account && form);
};

// Already initialized if there's an iframe with the portal frontend host
// within the div element. We check this because website devs can sometimes
// do funky things that could cause the form to accidentally initialize
// multiple times, which causes two forms to show in the same div.
// https://app.clubhouse.io/beaconcrm/story/1459/beacon-form-isn-t-loading-properly-on-olivia-newton-john-s-website
// const hasAlreadyInitialized = el => (
//   some(el.children, (child) => {
//     if (toLower(child.tagName) === 'iframe' && includes(child.src, portalHost)) {
//       return true;
//     }
//     return false;
//   })
// );

/**
 * Generate the iframe href including form and account path params from the
 * element dataset and a query string comprised of the following key items:
 * 1. required embed information
 * 2. the parent url stripped of special beacon query params
 * 3. beacon special static parameters
 * 4. form data population parameters derived from the element dataset
 * 5. form data population parameters derived from the parent query string
 */
const getEmbedUrl = (el, embedId) => {

  const {
    account,
    form,
  } = el.dataset;

  const parentUrl = window.location.href;
  const parentQuery = parse(parentUrl);

  const beaconStaticQueryKeys = [
    'access_token',
    'status',
    'error',
    'ga_debug',
    'beacon_debug',
  ];

  // Partition parent query into beacon-specific and non-beacon collections
  const queryPartition = Object.entries(parentQuery).reduce((acc, [k, v]) => {
    const categoryIndex = (
      k.startsWith('bcn_') || beaconStaticQueryKeys.indexOf(k) !== -1 ? 0 : 1
    );
    acc[categoryIndex][k] = v;
    return acc;
  }, [{}, {}]);

  const [beaconQueryParams, regularParams] = queryPartition;

  // Extract and process any url parameter mappings from the element dataset
  const dataParamsMapping = Object.fromEntries(
    Object.entries(el.dataset)
      .map(([k, v]) => [snakeCase(k), v])
      .filter(([k]) => k.startsWith('bcn_')),
  );

  const parentUrlWithoutBeaconQuery = `${parentUrl.split('?')[0]}${encode(regularParams)}`;

  // Build up a query string that can be appended to the url
  const queryParams = {
    embed: true,
    id: embedId,
    parent_url: parentUrlWithoutBeaconQuery,
    // Apply the dataset parameters if any
    ...dataParamsMapping,
    // Apply the beacon query parameterr - these should override any
    // identical dataset parameters
    ...beaconQueryParams,
  };
  if (env === 'development') {
    queryParams.account = account;
  }
  const query = encode(queryParams);

  const protocol = env === 'development' ? 'http' : 'https';

  // Don't use subdomains on dev
  if (env === 'development') {
    return `${protocol}://${portalHost}/form/${form}${query}`;
  }

  // we only use subdomains in production and staging
  return `${protocol}://${account}.${portalHost}/form/${form}${query}`;

};

export default (el) => {

  // TODO use the second `options` parameter to pass the visitor id to the
  // embedded form

  if (!isValid(el)) {
    return;
  }

  // If already initialized and has a frame inside, then don't do
  // anything.
  // Remove this for now as it was added to fix olivia newton foundation
  // and it ended up making things worse
  // if (hasAlreadyInitialized(el)) {
  //   return;
  // }

  const embedId = guid();

  const frame = document.createElement('iframe');
  frame.src = getEmbedUrl(el, embedId);
  frame.frameBorder = 0;

  frame.scrolling = 'no';
  frame.allowTransparency = true;
  frame.allowFullScreen = true;
  frame.allowPaymentRequest = true;
  frame.allow = 'payment';

  frame.style.overflow = 'hidden';
  frame.style.width = '100%';
  frame.style.height = '300px';
  frame.style.border = 'none';
  frame.style.visibility = 'visible'; // TODO not visible to start, only show when loaded

  // don't let the frame be selectable
  frame.style.userSelect = 'initial'; // standard
  frame.style.webkitUserSelect = 'none'; // Safari
  frame.style.mozUserSelect = 'none'; // Firefox
  frame.style.msUserSelect = 'none'; // IE10+/Edge

  // frame.style.pointerEvents = 'none';

  el.appendChild(frame);

  // Listen for clicks on the document, and send them down to the child frame,
  // so it can close open dropdowns on "click away"
  window.document.addEventListener('click', () => {
    const frameWindow = getFrameWindow(frame);

    // Safety check - don't post to child unless we definitely
    // can post to it. Chris: I'm assuming this is something to do with
    // the frame not being loaded yet.
    if (frameWindow && isFunction(frameWindow.postMessage)) {
      frameWindow.postMessage({
        type: 'parent_document_click',
      }, frame.src);
    }
  });

  // Listen for window messages
  window.addEventListener('message', (e) => {
    const message = e.data;

    debugLog('Received message', message);

    // Origin check
    if (!isValidMessage(e, el.dataset.account, embedId)) {
      debugLog('Invalid message, skipping');
      return;
    }

    switch (message.type) {

      // Set the height of the iframe - allows the embdded frame
      // to resize itself
      case 'set_height': {
        const { data } = message;
        const { height } = data;
        if (isNumber(height)) {
          frame.style.height = `${height}px`;
          frame.style.visibility = 'visible';
        }
        break;
      }

      // Scroll to a certain Y coordinate
      case 'scroll_to': {
        const { top } = getOffset(el);
        window.scrollTo(0, top + message.data.offsetY);
        break;
      }

      // Show the success confetti on success if enabled
      case 'show_confetti': {
        const { colors, timeout } = message.data;
        confetti(colors, timeout);
        break;
      }

      // When the child requests the google analytics ID, then wait for it, and
      // then pass it down to the child. Usually this will happen super fast and is
      // a near-synchronous operation
      case 'ga_client_id_request': {
        debugLog('Waiting for GA client');

        waitForClientId((clientId) => {
          const { data } = message;

          debugLog('Posting client ID to child', clientId);

          const frameWindow = getFrameWindow(frame);

          frameWindow.postMessage({
            type: 'ga_client_id_response',
            id: data.id,
            client_id: clientId,
          }, frame.src);
        });
        break;
      }

      case 'ga_event': {
        // eslint-disable-next-line camelcase
        const { event_type, event_name, data } = e.data.data;

        if (window.gtag) {

          // If Google analytics is running standalone on the page
          window.gtag(event_type, event_name, data);

        } else if (window.dataLayer && Array.isArray(window.dataLayer)) {

          // If Google Analytics is running via Google Tag manager (GTM), then we don't invoke
          // the `gtag` function. Instead, we push an event to GTM's "dataLayer" array.
          //
          // IMPORTANT: this does not auto-track to GA4 - the customer has to set up a "tag" in
          // GTM to listen for the "purchase" event pushed to the array - and then take
          // action accordingly.
          // eslint-disable-next-line camelcase
          const isEcommerce = event_name === 'add_to_cart' || event_name === 'purchase';

          // If it's an ecommerce event, pass the `data` into the special `ecommerce` parameter
          // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#make_a_purchase_or_issue_a_refund
          if (isEcommerce) {

            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
              event: event_name, // eslint-disable-line camelcase
              ecommerce: data, // ecom data
            });

          } else {

            // Otherwise just pass the data through as-is
            window.dataLayer.push({
              ...data, // pass along all of the custom data
              event: event_name, // eslint-disable-line camelcase
            });

          }

        }

        // If not either of the above, then don't track anything

        break;
      }

      default:
        break;
    }

  }, false);

  window.formFrame = frame;

};
