import env from './env';

const hosts = {
  production: 'beaconforms.com',
  staging: 'staging.beaconforms.com',
  development: 'dev.beaconforms.com:5003',
};
const host = hosts[env];

export default host;
