import getClientId from './getClientId';

export default (cb) => {

  let checks = 0;

  const interval = setInterval(() => {
    checks += 1;

    const clientId = getClientId();

    // As soon as we get an ID, call back with the ID
    if (clientId) {
      clearInterval(interval);
      cb(clientId);
    }

    // Don't check for longer than 10 seconds
    if (checks > 40) {
      clearInterval(interval);
    }

  }, 250);

};
