const randomInRange = (min, max) => (Math.random() * (max - min)) + min;

export default (colors, duration = 5000) => {

  const end = Date.now() + duration;
  const defaults = {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 1301,
    colors,
  };

  /*
  * 🎉
  * https://www.kirilv.com/canvas-confetti/
  */
  import(/* webpackChunkName: "confetti" */ 'canvas-confetti')
    .then(({ default: confetti }) => {

      const interval = setInterval(() => {
        const timeLeft = end - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);

        confetti({
          ...defaults,
          particleCount,
          origin: {
            x: randomInRange(0.0, 0.5),
            y: Math.random() - 0.2,
          },
        });

        confetti({
          ...defaults,
          particleCount,
          origin: {
            x: randomInRange(0.5, 1.0),
            y: Math.random() - 0.2,
          },
        });
      }, 250);

    }).catch(/* If import fails do nothing */);
};
