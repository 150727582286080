/*
* Get the window from an element. We don't use regular `window`
* because of iframes.
*/

export default (elem) => {
  const doc = elem.ownerDocument;
  const win = doc.defaultView || doc.parentWindow;
  return win;
};
