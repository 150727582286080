/*
 * Non-jQuery equivalent to jQuery `offset()`. Ripped straight
 * out of the jQuery core and modified to work without jQuery.
 * No arguments are accepted
 */
import contains from './contains';
import getWindow from './getWindow';

export default (elem) => {

  let box = {
    top: 0,
    left: 0,
  };

  let doc;

  if (elem && elem.ownerDocument) {
    doc = elem.ownerDocument;
  }

  if (!doc) {
    return;
  }

  const docElem = doc.documentElement;

  // Make sure it's not a disconnected DOM node
  if (!contains(docElem, elem)) {
    return box;
  }

  // Support: BlackBerry 5, iOS 3 (original iPhone)
  // If we don't have gBCR, just use 0,0 rather than error
  if (typeof elem.getBoundingClientRect !== typeof undefined) {
    box = elem.getBoundingClientRect();
  }

  // BUG: should never happen, but sometimes `win` is undefined. Added bug tracking
  // and returns 0,0 to help work out why this is.
  const win = getWindow(elem);
  if (!win) {
    return { top: 0, left: 0 };
  }

  return {
    top: box.top + win.pageYOffset - docElem.clientTop,
    left: box.left + win.pageXOffset - docElem.clientLeft,
  };
};
